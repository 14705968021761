import * as React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../../components/text-page';
import commonMessageValues from '../../templates/formatjs-message-values';

const PaymentInstitutions = (): JSX.Element => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.payment_institutions}`,
    message: <FormattedMessage id="message_payment_institutions_p1" values={commonMessageValues}/>
  }, {
    header: `${messages.the_merchant}`,
    message: <FormattedMessage id="message_payment_institutions_item1" values={commonMessageValues}/>
  }, {
    header: `${messages.the_customer}`,
    message: <FormattedMessage id="message_payment_institutions_item2" values={commonMessageValues}/>
  }, {
    header: `${messages.the_technology}`,
    message: <FormattedMessage id="message_payment_institutions_item3" values={commonMessageValues}/>
  }, {
    header: ``,
    message: <FormattedMessage id="message_payment_institutions_p2" values={commonMessageValues}/>
  }];

  return (<TextPage locale={intl.locale} title={`${messages.payment_institutions}`} textBlocks={textBlocks}/>);
};

export default PaymentInstitutions;